var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('ExportDownload',{attrs:{"isView":_vm.isView,"process-disable":_vm.processDisable,"url":_vm.imgUrl},on:{"exportForm":_vm.exportForm,"download":_vm.download}})]},proxy:true}])},[_c('a-tabs',{attrs:{"defaultActiveKey":"0","tabBarStyle":{
        margin: '0',
      }}},[_c('a-tab-pane',{key:"0",attrs:{"tab":_vm.stageName}})],1)],1),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"设计编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'designStageCode',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'designStageCode',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.projectName}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分项名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'branchName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'branchName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分项号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'branchCode',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'branchCode',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"成果内容","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'content',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'content',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"auto-size":{ minRows: 5 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("相关附件")]),_c('div',{staticClass:"link-list"},[_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }